import React from 'react'
import { Link } from 'gatsby'
import Menu from './menu'

//Images
import Logo from '../imgs/logo/wtf-logo.png'

export default function MainHeader() {

  return (
      <header>
        <div className="img-box">
          <div className="img">
            <Link to="/">
            <img  src={Logo} alt="the wtf collection header" />
            </Link>
          </div>
        </div>

        <Menu />
      </header>

  )
}