import React from 'react'
import { Link } from 'gatsby'
import "./menu.css"

const Menu = () => {
  return (
    <nav className="menu-box">
    <ul className="menu-list">
    <li className="menu-item">
        <Link className="menu-link" to="/">Home</Link>
      </li>
      <li className="menu-item">
        <Link className="menu-link" to="/blog">Blog</Link> 
      </li>

      <li className="menu-item">
        <Link className="menu-link" to="/rain">Rain</Link>
      </li>
      <li className="menu-item">
        <Link className="menu-link" to="/music">Music</Link>
      </li>
      <li className="menu-item">
        <Link className="menu-link" to="/podcasts">Podcasts</Link>
      </li>
            {/* <li className="menu-item">
        <Link className="menu-link" to="/shop">Shop</Link>
      </li> */}
      {/* <li className="menu-item">
        <Link className="menu-link" to="/sports">Sports</Link>
      </li> */}
      <li className="menu-item">
        <Link className="menu-link" to="/contact">Contact</Link>
      </li>
    </ul>
    
  </nav>
  )
}

export default Menu;