import React from 'react'
import { Link } from 'gatsby'
import "./footer.css"

export default function Footer() {

  return (
    <>
    <footer>

      <div className="footer-box">
        <p>&copy; The WTF Collection</p>
      </div>
    </footer>

    </>
  )
}