import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'



export default function Music () {
  return (
    <>
      <Layout>

        <SEO 
          title="Music"
          description="Just a place for music related news and stuff."
        />

        <div className="page-heading-box">
          <h1>MUSIC</h1>
        </div>
      
      </Layout>
    </>
  )
}